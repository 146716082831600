import ErrorReport from '@components/ui/ErrorReport';
import { withSentry } from '@sentry/remix';
import type { LinksFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';
import reactToastStyles from 'react-toastify/dist/ReactToastify.css?url';

import styles from './app.css?url';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: reactToastStyles },
];

export function meta() {
  return [{ title: 'vIP - Virtual In-Processing' }];
}

export const ErrorBoundary = ErrorReport;

function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Virtual In-Processing</title>
        <Meta />
        <Links />
      </head>
      <body className="text-slate-900 h-screen">
        {/* <div className="h-lvh flex flex-col"> */}
        <Outlet />
        {/* </div> */}
        <ScrollRestoration />
        <script>window.global = window;</script>
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);
